import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    privilegedUser: ['All'],
    component: () => import('../views/Home.vue'),
    isInSideMenu: false,
    meta: {
      title: 'Review Worx Pro',
    }
  },
  {
    path: '/login',
    name: 'Login',
    privilegedUser: ['All'],
    isInSideMenu: false,
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    isInSideMenu: true,
    privilegedUser: ['superadmin', 'business-admin', 'branch-manager'],
    component: () => import('../views/Dashboard.vue'),
    meta: {
      showSidebar: true,
      title: 'Dashboard',
    }
  },
  {
    path: '/dashboard/business/:business_id/history',
    name: 'DashboardHistory',
    isInSideMenu: true,
    privilegedUser: ['superadmin', 'business-admin'],
    component: () => import('../components/dashboard/DashboardHistory.vue'),
    meta: {
      showSidebar: true,
      title: 'Dashboard',
    }
  },
  {
    path: '/dashboard/business/:business_id',
    name: 'DashboardBusinessAdmin',
    isInSideMenu: true,
    privilegedUser: ['superadmin', 'business-admin'],
    component: () => import('../components/dashboard/DashboardBusinessAdmin.vue'),
    meta: {
      showSidebar: true,
      title: 'Dashboard',
    }
  },
  {
    path: '/dashboard/business/:business_id/location/:location_id',
    name: 'DashboardBranchManager',
    isInSideMenu: true,
    privilegedUser: ['superadmin', 'business-admin'],
    component: () => import('../components/dashboard/DashboardBranchManager.vue'),
    meta: {
      showSidebar: true,
      title: 'Dashboard',
    }
  },
  {
    path: '/businesses',
    name: 'Businesses',
    isInSideMenu: true,
    privilegedUser: ['superadmin'],
    component: () => import('../views/Businesses.vue'),
    meta: {
      showSidebar: true,
      title: 'Businesses',
    },
  },
  {
    path: '/businesses/:business_id/locations',
    name: 'BusinessLocations',
    privilegedUser: ['superadmin'],
    component: () => import('../views/Locations.vue'),
    meta: {
      showSidebar: true,
      title: "Businesses"
    }
  },
  {
    path: '/businesses/:business_id/users',
    name: 'BusinessUsers',
    privilegedUser: ['superadmin'],
    component: () => import('../views/Users.vue'),
    meta: {
      showSidebar: true,
      title: "Businesses"
    }
  },
  {
    path: '/businesses/:business_id/mass-text-history',
    name: 'BusinessMassTextHistory',
    privilegedUser: ['superadmin'],
    component: () => import('../views/MassTextHistory.vue'),
    meta: {
      showSidebar: true,
      title: "Businesses"
    },
  },
  {
    path: '/businesses/:business_id/mass-text-history/:mass_textid',
    name: 'SingleMassText',
    privilegedUser: ['superadmin'],
    component: () => import('../components/SingleMassText.vue'),
    meta: {
      showSidebar: true,
      title: "Businesses"
    }
  },
  {
    path: '/businesses/:business_id/web-chat',
    name: 'BusinessWebChat',
    privilegedUser: ['superadmin'],
    component: () => import('../views/WebChat.vue'),
    meta: {
      showSidebar: true,
      title: "Businesses"
    }
  },
  {
    path: '/locations',
    name: 'Locations',
    isInSideMenu: true,
    privilegedUser: ['business-admin'],
    component: () => import('../views/Locations.vue'),
    meta: {
      showSidebar: true,
      title: 'Locations',
    }
  },
  {
    path: '/users',
    name: 'Users',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../views/Users.vue'),
    meta: {
      showSidebar: true,
      title: 'Users',
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    isInSideMenu: false,
    privilegedUser: ['All'],
    component: () => import('../views/Profile.vue'),
    meta: {
      showSidebar: true,
      title: 'Profile',
    }
  },
  {
    path: '/mass-text/phone-lists',
    name: 'Phone Lists',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../views/MassTextPhoneLists.vue'),
    meta: {
      showSidebar: true,
      title: 'Phone Lists',
    }
  },
  {
    path: '/mass-text/history/:single_masstext',
    name: 'Mass Text Single',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../components/SingleMassText.vue'),
    meta: {
      showSidebar: true,
      title: 'Mass Text Single',
    }
  },
  {
    path: '/mass-text/phone-lists/:contact_group_id',
    name: 'Single Phone',
    isInSideMnu: false,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../components/SinglePhoneComponent.vue'),
    meta: {
      showSidebar: true,
      title: 'Phone Lists'
    }
  },
  {
    path: '/mass-text/compose',
    name: 'Compose',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../views/MassTextCompose.vue'),
    meta: {
      showSidebar: true,
      title: 'Compose',
    },
    children: [
      {
        path: '/mass-text/compose/:masstext_id',
        name: 'Compose',
        isInSideMenu: true,
        privilegedUser: ['business-admin', 'branch-manager'],
        component: () => import('../views/MassTextCompose.vue'),
        meta: {
          showSidebar: true,
          title: 'Compose',
        },
      },
    ],
  },

  {
    path: '/mass-text/history',
    name: 'History',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager'],
    component: () => import('../views/MassTextHistory.vue'),
    meta: {
      showSidebar: true,
      title: 'History',
    }
  },
  {
    path: '/messaging/conversations',
    name: 'Conversations',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager', 'branch-staff'],
    component: () => import('../views/MessagingConversations.vue'),
    meta: {
      showSidebar: true,
      title: 'Conversations',
    }
  },
  {
    path: '/messaging/conversation/:conversation_id',
    name: 'Conversation Single',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager', 'branch-staff'],
    component: () => import('../components/SingleConversation.vue'),
    meta: {
      showSidebar: true,
      title: 'Single Conversation',
    }
  },
  {
    path: '/messaging/send-text',
    name: 'Send Text',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager', 'branch-staff'],
    component: () => import('../views/MessagingSendText.vue'),
    meta: {
      showSidebar: true,
      title: 'Send Text',
    }
  },
  {
    path: '/messaging/review-text',
    name: 'Review Text',
    isInSideMenu: true,
    privilegedUser: ['business-admin', 'branch-manager', 'branch-staff'],
    component: () => import('../views/MessagingReviewText.vue'),
    meta: {
      showSidebar: true,
      title: 'Review Text',
    }
  },
  {
    path: '/billing/account',
    name: 'Account',
    isInSideMenu: true,
    privilegedUser: ['business-admin'],
    component: () => import('../views/BillingAccount.vue'),
    meta: {
      showSidebar: true,
      title: 'Account',
    }
  },
  {
    path: '/billing/invoices',
    name: 'Invoices',
    isInSideMenu: true,
    privilegedUser: ['business-admin'],
    component: () => import('../views/BillingInvoices.vue'),
    meta: {
      showSidebar: true,
      title: 'Invoices',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();

})

export default router
