<template>
  <div>
    <div
      v-if="this.$route.meta.showSidebar"
      class="h-screen flex overflow-hidden bg-gray-100"
    >
      <SidebarMobile
        :sidebar-open="sidebarOpen"
        @toggleSidebar="toggleSidebar()"
      />
      <SidebarMenu />
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <Topbar @toggleSidebar="toggleSidebar()" />
        <main
          class="flex-1 relative overflow-y-auto focus:outline-none"
          tabindex="0"
        >
          <div class="py-6">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 class="text-2xl font-semibold text-gray-900">
                <!-- {{ pageHeader }} -->
              </h1>
            </div>
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <router-view />
            </div>
          </div>
        </main>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import SidebarMobile from "./components/SidebarMobile.vue";
import SidebarMenu from "./components/SidebarMenu.vue";
import Topbar from "./components/Topbar.vue";
const firebase = require("./firebaseConfig");

export default {
  components: {
    SidebarMobile,
    SidebarMenu,
    Topbar,
  },
  data: () => ({
    sidebarOpen: false,
    showSidebar: false,
    pageHeader: "",
    userData: {},
  }),
  mounted() {
    this.pageHeader = this.$route.meta.title;
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    getBreadCrumbs() {},
    getUser() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot({ includeMetadataChanges: true }, (docs) => {
              this.userData = {
                uid: docs.id,
                ...docs.data(),
              };
              resolve({ uid: docs.id, ...docs.data() });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
  },
  watch: {
    $route() {
      this.pageHeader = this.$route.meta.title;
      this.getUser()
        .then((user) => {
          this.userData = user;
          const routes = this.$router.options.routes.filter((route) => {
            if (
              route.privilegedUser.includes(user.role) ||
              route.privilegedUser.includes("All")
            ) {
              return route;
            }
          });
          if (!routes.some((route) => route.name === this.$route.name)) {
            this.$router.push(
              user.role != "branch-staff"
                ? "/dashboard"
                : "/messaging/conversations"
            );
          }
        })
        .catch(() => {
          if (this.$route.path !== "/" && this.$route.path !== "/login")
            this.$router.push({
              name: "Login",
              params: {
                message:
                  "Please login with your account first, or request a demo",
              },
            });
        });
    },
  },
};
</script>
