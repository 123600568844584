<template>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div v-show="sidebarOpen" class="md:hidden">
    <div class="fixed inset-0 flex z-40">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="translate-x-0"
        leave-to-class="opacity-100"
      >
        <!-- Off-canvas menu overlay, show/hide based on off-canvas menu state. -->
        <div v-show="sidebarOpen" class="fixed inset-0" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
      </transition>
      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <!-- Off-canvas menu, show/hide based on off-canvas menu state. -->
        <div
          v-show="sidebarOpen"
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
        >
          <div class="absolute top-0 right-0 -mr-12 pt-2">
            <button
              @click="toggleSidebar()"
              class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: x -->
              <svg
                class="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="flex-shrink-0 flex items-center px-4">
            <img
              class="h-auto"
              src="../assets/images/rwp-logo.png"
              alt="Chat Handler"
            />
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <router-link
                to="/dashboard"
                v-if="user.role != 'branch-staff'"
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon name: home -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                Dashboard
              </router-link>

              <router-link
                to="/businesses"
                v-if="user.role == 'superadmin'"
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                Businesses
              </router-link>

              <router-link
                to="/locations"
                v-if="user.role == 'business-admin'"
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Locations
              </router-link>

              <router-link
                to="/users"
                v-if="
                  user.role == 'business-admin' || user.role == 'branch-manager'
                "
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Users
              </router-link>

              <a
                @click.prevent="toggleMasstextMenu()"
                href="/mass-text"
                v-if="
                  (user.role == 'business-admin' ||
                    user.role == 'branch-manager') &&
                    business.mass_text_active == true
                "
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                Mass Texting
              </a>
              <transition
                enter-active-class="transition ease-out duration-500"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-375"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div>
                  <router-link
                    v-show="masstextMenu"
                    to="/mass-text/phone-lists"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                    Phone Lists
                  </router-link>

                  <router-link
                    v-show="masstextMenu"
                    to="/mass-text/compose"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                    Compose
                  </router-link>

                  <router-link
                    v-show="masstextMenu"
                    to="/mass-text/history"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                      />
                    </svg>
                    History
                  </router-link>
                </div>
              </transition>

              <a
                @click.prevent="toggleMessagingMenu()"
                href="/messaging"
                v-if="
                  user.role == 'business-admin' ||
                    user.role == 'branch-manager' ||
                    user.role == 'branch-staff'
                "
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
                Messaging
              </a>

              <transition>
                <div>
                  <router-link
                    v-show="messagingMenu"
                    to="/messaging/conversations"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                      />
                    </svg>
                    Conversations
                  </router-link>

                  <router-link
                    v-show="messagingMenu"
                    to="/messaging/send-text"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Send Text
                  </router-link>

                  <router-link
                    v-show="messagingMenu"
                    to="/messaging/review-text"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Review Text
                  </router-link>
                </div>
              </transition>

              <a
                @click.prevent="toggleBillingMenu()"
                href="/billing"
                v-if="
                  user.role == 'business-admin' &&
                    business.business_type == 'stripe'
                "
                class="group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <!-- Heroicon -->
                <svg
                  class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Billing
              </a>

              <transition>
                <div>
                  <router-link
                    v-show="billingMenu"
                    to="/billing/account"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                    Account
                  </router-link>

                  <router-link
                    v-show="billingMenu"
                    to="/billing/invoices"
                    class="group flex items-center pl-10 pr-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                  >
                    <!-- Heroicon -->
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                      />
                    </svg>
                    Invoices
                  </router-link>
                </div>
              </transition>
            </nav>
          </div>
        </div>
      </transition>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
export default {
  name: "SidebarMobile",
  props: ["sidebarOpen"],
  data: () => ({
    masstextMenu: false,
    messagingMenu: false,
    billingMenu: false,
    business: {},
    user: {},
  }),
  async mounted() {
    this.user = await this.getUser();
    this.getBusinessInfo();
    this.expandMessages();
  },
  methods: {
    getUser() {
      return new Promise((resolve, reject) => {
        try {
          if (firebase.auth.currentUser.uid) {
            firebase.db
              .collection("users")
              .doc(firebase.auth.currentUser.uid)
              .onSnapshot((doc) => {
                resolve({
                  uid: doc.id,
                  ...doc.data(),
                });
              });
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    async expandMessages() {
      const user = await this.getUser();

      if (user.role == "branch-staff") this.messagingMenu = !this.messagingMenu;
    },
    async getBusinessInfo() {
      this.business = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get();
      this.business = this.business.data();
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    toggleMasstextMenu() {
      this.masstextMenu = !this.masstextMenu;
      // this.messagingMenu = false;
      // this.billingMenu = false;
    },
    toggleMessagingMenu() {
      // this.masstextMenu = false;
      this.messagingMenu = !this.messagingMenu;
      // this.billingMenu = false;
    },
    toggleBillingMenu() {
      // this.masstextMenu = false;
      // this.messagingMenu = false;
      this.billingMenu = !this.billingMenu;
    },
  },
};
</script>

<style scoped>
.router-link-active,
.router-link-exact-active {
  background-color: rgb(17, 24, 39);
  color: #ffffff;
}
</style>
