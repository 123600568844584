import { render } from "./SidebarMobile.vue?vue&type=template&id=1659d81e&scoped=true"
import script from "./SidebarMobile.vue?vue&type=script&lang=js"
export * from "./SidebarMobile.vue?vue&type=script&lang=js"

import "./SidebarMobile.vue?vue&type=style&index=0&id=1659d81e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-1659d81e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1659d81e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1659d81e', script)) {
    api.reload('1659d81e', script)
  }
  
  module.hot.accept("./SidebarMobile.vue?vue&type=template&id=1659d81e&scoped=true", () => {
    api.rerender('1659d81e', render)
  })

}

script.__file = "src/components/SidebarMobile.vue"

export default script