<template>
  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-80">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col h-0 flex-1">
        <div class="flex items-center flex-shrink-0 p-4 bg-gray-900">
          <img
            class="h-auto"
            src="../assets/images/rwp-logo.png"
            alt="Chat Handler"
          />
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <nav class="flex-1 px-2 py-4 bg-gray-800 space-y-1">
            <router-link
              to="/dashboard"
              v-if="user.role != 'branch-staff'"
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon name: home -->
              <svg
                class="mr-3 h-6 w-6 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
              Dashboard
            </router-link>

            <router-link
              v-if="user.role == 'superadmin'"
              to="/businesses"
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <!-- super admin -->
              Businesses
            </router-link>

            <router-link
              to="/locations"
              v-if="user.role == 'business-admin'"
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <!-- business owner -->
              Locations
            </router-link>

            <router-link
              to="/users"
              v-if="
                user.role == 'business-admin' || user.role == 'branch-manager'
              "
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <!-- business owner -->
              <!-- branch manager -->
              Users
            </router-link>

            <a
              @click.prevent="toggleMasstextMenu()"
              v-if="
                (user.role == 'business-admin' ||
                  user.role == 'branch-manager') &&
                  business.mass_text_active == true
              "
              href="/mass-texting"
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              <!-- business owner -->
              <!-- branch manager -->
              Mass Texting
            </a>

            <transition
              enter-active-class="transition ease-out duration-500"
              enter-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-375"
              leave-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <div>
                <router-link
                  v-show="masstextMenu"
                  to="/mass-text/phone-lists"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Phone Lists
                </router-link>

                <router-link
                  v-show="masstextMenu"
                  to="/mass-text/compose"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  Compose
                </router-link>

                <router-link
                  v-show="masstextMenu"
                  to="/mass-text/history"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                    />
                  </svg>
                  History
                </router-link>
              </div>
            </transition>

            <a
              @click.prevent="toggleMessagingMenu()"
              href="/messaging"
              v-if="
                user.role == 'business-admin' ||
                  user.role == 'branch-manager' ||
                  user.role == 'branch-staff'
              "
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              Messaging
            </a>

            <transition>
              <div>
                <router-link
                  v-show="messagingMenu"
                  to="/messaging/conversations"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    />
                  </svg>
                  Conversations
                </router-link>

                <router-link
                  v-show="messagingMenu"
                  to="/messaging/send-text"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Send Text
                </router-link>

                <router-link
                  v-show="messagingMenu"
                  to="/messaging/review-text"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Review Text
                </router-link>
              </div>
            </transition>

            <a
              @click.prevent="toggleBillingMenu()"
              href="/billing"
              v-if="
                user.role == 'business-admin' &&
                  business.business_type == 'stripe'
              "
              class="group flex items-center px-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
            >
              <!-- Heroicon -->
              <svg
                class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Billing
            </a>

            <transition>
              <div>
                <router-link
                  v-show="billingMenu"
                  to="/billing/account"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  Account
                </router-link>

                <router-link
                  v-show="billingMenu"
                  to="/billing/invoices"
                  class="group flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                >
                  <!-- Heroicon -->
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                    />
                  </svg>
                  Invoices
                </router-link>
              </div>
            </transition>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
export default {
  name: "SidebarMenu",
  data: () => ({
    masstextMenu: false,
    messagingMenu: false,
    billingMenu: false,
    business: {},
    user: {},
  }),
  async mounted() {
    this.user = await this.getUser();
    this.getBusinessInfo();
    this.expandMessages();
  },
  methods: {
    getUser() {
      return new Promise((resolve, reject) => {
        try {
          if (firebase.auth.currentUser.uid) {
            firebase.db
              .collection("users")
              .doc(firebase.auth.currentUser.uid)
              .onSnapshot((doc) => {
                resolve({
                  uid: doc.id,
                  ...doc.data(),
                });
              });
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    async expandMessages() {
      const user = await this.getUser();

      if (user.role == "branch-staff") this.messagingMenu = !this.messagingMenu;
    },
    async getBusinessInfo() {
      const user = await this.getUser();
      this.business = await firebase.db
        .collection("businesses")
        .doc(user.business_id)
        .get();
      this.business = this.business.data();
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    toggleMasstextMenu() {
      this.masstextMenu = !this.masstextMenu;
      // this.messagingMenu = false;
      // this.billingMenu = false;
    },
    toggleMessagingMenu() {
      // this.masstextMenu = false;

      this.messagingMenu = !this.messagingMenu;
      // this.billingMenu = false;
    },
    toggleBillingMenu() {
      // this.masstextMenu = false;
      // this.messagingMenu = false;
      this.billingMenu = !this.billingMenu;
    },
  },
};
</script>

<style scoped>
.router-link-active,
.router-link-exact-active {
  background-color: rgb(17, 24, 39);
  color: #ffffff;
}
</style>
