import { render } from "./Topbar.vue?vue&type=template&id=458ce5ce"
import script from "./Topbar.vue?vue&type=script&lang=js"
export * from "./Topbar.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "458ce5ce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('458ce5ce', script)) {
    api.reload('458ce5ce', script)
  }
  
  module.hot.accept("./Topbar.vue?vue&type=template&id=458ce5ce", () => {
    api.rerender('458ce5ce', render)
  })

}

script.__file = "src/components/Topbar.vue"

export default script