<template>
  <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
    <button
      @click="toggleSidebar()"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
    >
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: menu-alt-2 -->
      <svg
        class="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </button>
    <div class="flex-1 px-4 flex justify-between">
      <div class="flex-1 flex"></div>
      <div class="flex items-center md:ml-6">
        <!-- Profile dropdown -->
        <div class="relative">
          <div>
            <button
              @click="dropdownOpen = !dropdownOpen"
              class="max-w-xs bg-white flex items-center text-sm px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
              id="user-menu"
              aria-haspopup="true"
            >
              <span class="sr-only">Open user menu</span>
              <span class="text-lg px-2">{{ user.display_name }}</span>
              <!-- Heroicon name: user -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </button>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div
              v-show="dropdownOpen"
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              @click="dropdownOpen = !dropdownOpen"
              aria-labelledby="user-menu"
            >
              <router-link
                to="/profile"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                >Your Profile</router-link
              >

              <a
                @click.prevent="logout"
                href="#"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                >Sign out</a
              >
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
import router from "../router";

export default {
  name: "Topbar",
  data: () => ({
    dropdownOpen: false,
    user: {},
  }),
  mounted() {
    this.getUserInfo();
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    getUserInfo() {
      try {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .onSnapshot((doc) => {
            this.user = {
              id: doc.id,
              ...doc.data(),
            };
          });
      } catch (e) {
        this.$router.push("/login");
      }
    },
    logout() {
      firebase.auth
        .signOut()
        .then(function() {
          router.push("/login");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
