import { render } from "./SidebarMenu.vue?vue&type=template&id=c478414a&scoped=true"
import script from "./SidebarMenu.vue?vue&type=script&lang=js"
export * from "./SidebarMenu.vue?vue&type=script&lang=js"

import "./SidebarMenu.vue?vue&type=style&index=0&id=c478414a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-c478414a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "c478414a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c478414a', script)) {
    api.reload('c478414a', script)
  }
  
  module.hot.accept("./SidebarMenu.vue?vue&type=template&id=c478414a&scoped=true", () => {
    api.rerender('c478414a', render)
  })

}

script.__file = "src/components/SidebarMenu.vue"

export default script